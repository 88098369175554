import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import { useUserInfoContext } from 'UserInfo.js';

export function DocsList({user, ...props}) {
	let uinfo = useUserInfoContext()
	if(user) uinfo = user
	let cdo_list = uinfo.kv.cdo_list

    return (<>
    {
        <div className="card">
        <h2>Документы об образовании</h2>
        {
        Object.keys(cdo_list).length > 0 ?
        Object.keys(cdo_list).map((k, v) => {
                return (<DoctypeBlock ed_level={k} cdo_list={cdo_list[k]} />);
            }
        )
        :
        <div className="error">
  	  		    У Вас пока нет добавленных документов!
        </div>
        }
        </div>
    }
    </>
    )
}

function DocBlock({cdo, ...props}){
	return (
	    <>
	        <hr style={{ marginTop:'2em', marginBottom:'2em'}}/>
            <div className="flexContainer notWrap">
                <div>
                    <div className="infoBlock">
                        <p>Наименование организации</p>
                        <h4>{cdo.gov}</h4>
                    </div>
                    <div className="infoBlock">
                        <p>Тип документа</p>
                        <h4>{cdo.doctype_name}</h4>
                    </div>
                </div>
                <Link className="pinRight" to={"/doc/"+cdo.url+cdo.hash} target="_blank"
                    ><button className="notWrap">Перейти к документу
                </button></Link>
            </div>
        </>
    )
}

function DoctypeBlock({ed_level, cdo_list, ...props}){
    return(
        <div className="card">
            <h2>{window.AppBundle.data.ed_level_labels.decode[ed_level]}</h2>
            {
                cdo_list.map(cdo=>
                    <DocBlock cdo={cdo}/>
                )
            }
        </div>
    )
}