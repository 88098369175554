import { useLocation, useParams } from "react-router-dom";

import { Async } from 'azlib/components/async'
import { mainDbX } from 'azlib/components/db';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { responseText } from 'azlib/components/access'
import { generateRandStr
    , encryptData
    , decryptData
    , exportPbkdf2Key
    , jsonEscapeUTF
    , base64DecodeUrl
} from 'azlib/components/crypt'

import { ReactComponent as ImgLogo } from "app-img/logo1.svg";
import { ReactComponent as ImgRectangle } from "app-img/rectangle.svg";
import DocLoader from 'docloader/DocLoader'
import { passwordValidation } from "registration/components/regValidations";
import * as yup from 'yup';

async function getUserPart(url, hash){
    try{
        let res = await responseText(await mainDbX.fetch_get("/repwd/"+url, {}))
        if(res.error)
            throw res
        let ck = await decryptData(atob(res.ukey), base64DecodeUrl(hash))

        if(!ck)
            throw {error: "Доступ запрещен"}

        ck = JSON.parse(ck)
        let kv_old = await decryptData(atob(ck.p), res.kv)
        if(!kv_old || !JSON.parse(kv_old))
            throw {error: "Доступ запрещен"}

        ck.kv = kv_old
        return ck
    }catch(err){
        return err
    }
}

const RestorePage = () => {

    const location = useLocation();
    const hash = decodeURIComponent(location.hash.replace('/', '').slice(1));
    const url = useParams().url;

    const handleRepass = async (pwd, ck) => {
        console.log(pwd, ck)
        let login_hash = btoa(await exportPbkdf2Key(ck.l, ck.l))
        let salt = generateRandStr(256)
        let pwd_hash = await exportPbkdf2Key(pwd, salt)

        let magic = generateRandStr(256)
        let magic_hash = btoa(await exportPbkdf2Key(await exportPbkdf2Key(magic, pwd_hash), atob(login_hash)))
        let kv_new = await encryptData(pwd_hash, jsonEscapeUTF(ck.kv))

        let pwd_info = JSON.stringify({
            phrase: ck.ph
            , password: btoa(pwd_hash)
        });
        let phrase_hash = await exportPbkdf2Key(ck.ph, salt)
        let pwd_crypted = await encryptData(phrase_hash, jsonEscapeUTF(pwd_info))
        let phrase_crypted = await encryptData(pwd_hash, ck.ph)

        let data = await encryptData(atob(ck.m), jsonEscapeUTF(JSON.stringify({
            login: login_hash
            , magic: btoa(magic)
            , magic_hash: magic_hash
            , salt: btoa(salt)
            , kv: kv_new
            , phrase_crypted: phrase_crypted
        })))

        await responseText(await mainDbX.fetch_post("/repwd/"+login_hash,
            {
                data: data
                , pwd_crypted: pwd_crypted
            }))
        .then((res) => {
            if(res.error)
                throw res.error
            alert("Пароль успешно изменен!")
            window.location.replace("/")
        })
        .catch((err) => alert(err))
    }

    return (
        <Async value={
            getUserPart(url, hash)
        }
        fallback={
            <DocLoader/>
		}
		>{row=>
		<>{
            row.error ?
            <div style={{
                    margin: "5em auto 1em auto"
                    , textAlign: "center"
                    , fontSize: "40pt"
                    , fontWeight: "bold"
                    , color: "#ad3131"
                    }}
                >
                {row.error}
            </div>
            :
            <div className="registration-container" >
                <div className="form-wrapper fixed" >
                    <div className="form-title">Личный кабинет</div>
                    <div className="logo-container">
                        <ImgRectangle className="registration-background" />
                        <ImgLogo className="registration-logo" />
                    </div>
                    <Formik
                        initialValues={{ pwd: '', pwd_rep: '' }}
                        validationSchema={
                            yup.object({
                                pwd: passwordValidation
                                , pwd_rep: yup.string()
                                    .required('Обязательно')
                                    .oneOf([yup.ref('pwd'), null], 'Пароли должны совпадать')
                            })
                        }
                        onSubmit={ async (values, event) => await handleRepass(values.pwd, row) }
                    >
                        <Form className="flexContainerColumn">
                            <p className='form-section-title'>Восстановление доступа</p>
                            <p className="form-email">{row.l}</p>

                            <p className="form-field-label">Новый пароль</p>
                            <Field type="password" name="pwd" />
                            <ErrorMessage name="pwd" component="div" className="error" />
                            <small>Пароль должен содержать символы в верхнем и нижнем регистре, цифры и спецсимволы: @$!%*#?&</small>

                            <p className="form-field-label">Подтвердите пароль</p>
                            <Field type="password" name="pwd_rep" />
                            <ErrorMessage name="pwd_rep" component="div" className="error" />
                            <button type="submit">Изменить</button>
                        </Form>
                    </Formik>
                </div>
            </div>
        }</>
        }</Async>
    )
}

export default RestorePage;