import { saveAccountKV } from 'accounts/account'
import { ReactComponent as Img_warning } from "app-img/warning.svg";
import { ModalButton } from 'azlib/components/modals'

import { useUserInfoContext } from 'UserInfo'
import { usePermitContext } from 'docs/doc'

export default function DocDelete({doc, ...props}) {

    const uinfo = useUserInfoContext()
    const [, setPermit] = usePermitContext()

    return(
        <div className="form-wrapper fixed flexContainerColumn">
            <Img_warning className="logo"/>
            <p className="form-title">ПОСЛЕ ПОДТВЕРЖДЕНИЯ ДОКУМЕНТ БУДЕТ БЕЗВОЗВРАТНО УДАЛЕН.
            ПРИ НЕОБХОДИМОСТИ ВОСПОЛЬЗУЙТЕСЬ ССЫЛКОЙ НА ДОКУМЕНТ ДЛЯ ПОВТОРНОГО ДОБАВЛЕНИЯ</p>
            <ModalButton className="del"
                onClick={async (v, ops)=>{
                    await deleteFromAccount(doc, uinfo)
                    setPermit('-a')
                }}>
                УДАЛИТЬ
            </ModalButton>
			<ModalButton>Отмена</ModalButton>
        </div>
    )
}

async function deleteFromAccount(doc, uinfo){
    let ed_level = doc.doctype_kind.split('-')[0] === 'mp'? 'mp' : doc.doctype_kind.split('-')[0].charAt(0);

    uinfo.kv.cdo_list[ed_level] = uinfo.kv.cdo_list[ed_level].filter((cdo) => cdo.url !== doc.url);
    delete uinfo.kv.docs_shared[doc.url]

    if(uinfo.kv.cdo_list[ed_level].length === 0)
        delete uinfo.kv.cdo_list[ed_level];

    return await saveAccountKV(uinfo, uinfo.login, uinfo.kv)
}