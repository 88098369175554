import React, { useContext, useRef, createContext, useEffect, useState } from 'react';

import { Routes, Route, useParams, useLocation } from "react-router-dom";

import { Async } from 'azlib/components/async'
import { decryptData } from 'azlib/components/crypt'
import { mainDbX } from 'azlib/components/db'
import { copyToBuffer } from 'azlib/components/helpers'
import { fullPersonName, fullPersonNameLat } from 'azlib/components/name_funcs'
import { PopupModal, Button } from 'azlib/components/modals'

import { useUserInfoContext } from 'UserInfo'
import { useDeviceContext } from 'UserDevice'

import {Infoset, Orginfo, Spec, Signers} from './infoset'
import DocLoader from 'docloader/DocLoader'

import DocAdd from './doc_add'
import { DocShare } from './doc_share'
import DocDelete from './doc_delete'

import css from "./infoset.module.css";

export const PermitContext = createContext()
export function usePermitContext() { return useContext(PermitContext) }

async function get_doc(pathname, hash){
    var data = await mainDbX.fetch_get('/app/archive/get_doc',{ url: pathname });
    data = await data.text();
    return data ? JSON.parse(await decryptData(hash, JSON.parse(data))) : null;
}

export function docFindExtParam(doc, param){
    let v = doc.ext_info.find((e) => e.parameter === param)? doc.ext_info.find((e) => e.parameter === param).value : null;
    return v;
}

function checkDocPermits(doc, uinfo){
    let permit = '-na'
    if (uinfo.userAreas.user && docFindExtParam(doc, 'master_key')){
        permit = '-a'
        Object.keys(uinfo.kv.cdo_list).forEach((k) =>
            uinfo.kv.cdo_list[k].forEach((cdo) =>
                {
                    if(cdo.url === doc.url)
                        permit = '-o'
                }
            )
        )
    }
    return permit;
}

function DocUpperMenu({row, ...props}){
    const [permit] = usePermitContext();

    const location = useLocation();
    const hash = location.hash;

    return(
        permit === '-o' ?
            <div className={"flexContainer "+css.docBar}>
				<button
                    onClick={async ()=>{
                        // пока просто передаю хеш
						let response = await mainDbX.fetch_post('/app/sign/sign_pdf', { url: row.url, hash: hash.slice(1) });
                        //Архив с pdf + .sig
                        if (response.ok) {
                            let zipBlob = await response.blob();
                            let link = document.createElement('a');
                            link.href = URL.createObjectURL(zipBlob);
                            link.download = "Выписка.zip";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }}>СФОРМИРОВАТЬ ВЫПИСКУ
                </button>

                <PopupModal
                    trigger={(handlers)=><Button {...handlers}>НАСТРОЙКИ ДОСТУПА</Button>}>
                    <DocShare doc={row}/>
                </PopupModal>

                <PopupModal
                    trigger={(handlers)=><Button className="del" {...handlers}>УДАЛИТЬ ДОКУМЕНТ ИЗ ЛК</Button>}>
                    <DocDelete doc={row}/>
                </PopupModal>
            </div>
        : null
    )
}

function DocLowerMenu({row, ...props}){
    const [permit] = usePermitContext();

    const location = useLocation();
    const hash = location.hash;

    return permit !== '-na' && <div className="flexcontainer">
        <PopupModal
            trigger={(handlers)=><Button className={permit === '-o' ? "del" : "block"} {...handlers}>
            {permit === '-o' ? "УДАЛИТЬ ДОКУМЕНТ ИЗ ЛК" : "ДОБАВИТЬ ДОКУМЕНТ В ЛК"}</Button>}>
            {
            permit === '-o'
                ? <DocDelete doc={row}/>
                : <DocAdd doc={row} hash={hash}/>
            }
        </PopupModal>
    </div>
}

function Doc({row, ...props}) {
    const [permit, setPermit] = usePermitContext()
    const uinfo = useUserInfoContext();
	const mobile = useDeviceContext();

	const ourNumRef = useRef(null)
	const dtype = row.doctype_kind.endsWith('good') ? 'good' : row.doctype_kind.split('-')[0];
	const dt_name = dtype === "ps" ? row.spec_name : row.doctype_name

    useEffect(() => {
        setPermit(checkDocPermits(row, uinfo))
    },[])

	return (
		<>
		    <DocUpperMenu row={row}/>
		    <div className={css.mainDocCard+' '+css[dtype]+' '+(!!mobile?css.mobile:'')}>
				<div className={css.cardHeader+' '+(!!mobile?css.mobile:'')}>
				    {dtype === 'good' && !mobile?
				        <h1 className={css.cardHeadGood}>С отличием</h1>
				        : null
				    }
				</div>

				<div className={css.card}>
				    <div className="flexContainer">
				    {
				        mobile ?
				            <>
                            <h3 style={{textAlign: "center"}}>
                                {dt_name}
                            </h3>
                            <div className="infoBlock pinRight">
                                <div className="flexContainer">
                                    <h4>№ {row.our_number}</h4>
                                    <button className="copyBtn" onClick={ async()=>{
                                        await copyToBuffer(row.our_number)
                                    }}
                                    />
                                </div>
                            </div>
                            </>
				        :
				            <>
                            <h1 style={{marginBottom: 0, marginRight: "2em"}}>
                                {dt_name}
                            </h1>
                            <div className="infoBlock">
                                <p>Статус</p>
                                <h4>{row.status}</h4>
                            </div>
                            <div className="infoBlock" style={{ margin:'auto 0 0 auto' }}>
                                <div className="flexContainer">
                                    <input
                                        readOnly={true}
                                        type="text"
                                        style = {{ width: 0, height: 0, padding: 0, outline: 'none' }}
                                        value = {row.our_number}
                                        ref={ourNumRef}
                                    />
                                    <p style={{ display: 'inline-block', textAlign: 'right', fontSize: '22px', fontWeight: 'bold' }}>№ {row.our_number}</p>
                                    <button className="copyBtn" onClick={()=>{
                                        ourNumRef.current.focus()
                                        ourNumRef.current.select()
                                        document.execCommand('copy')
                                        alert('Номер скопирован в буфер обмена')
                                    }}
                                    />
                                </div>
                            </div>
                            </>
				    }
                    </div>
                    <div className="flexContainer">
                        <div className="infoBlock">
                            <p>Дата выдачи</p>
                            <h4>{row.our_date}</h4>
                        </div>
                        {dtype === "ps" &&
                            <div className="infoBlock" style={{marginLeft: "2em"}}>
                                <p>Срок действия</p>
                                <h4>{row.validity_period ? `до ${row.validity_period}` : 'бессрочно'}</h4>
                            </div>
                        }
                    </div>
		            <div className={css.ownerName+' '+css[dtype]}>
                        <h2>{ dtype === "ps" ?
                            fullPersonNameLat(row)
                            : fullPersonName(row)
                        }</h2>
		            </div>
                    <div className="infoBlock">
                        <p>Дата рождения</p>
                        <h4>{row.bdate}</h4>
                    </div>
		        </div>
		    </div>

		    <div className={css.card}>
		        <Orginfo value={row.infoset_orginfo} dtype={dtype} mobile={mobile}/>
		        {row.spec_name && dtype !== "ps" &&
                    <Spec value={{
                        spec_name: row.spec_name
                        , spec_code: row.spec_code
                        , qualification: row.qualification
                        , qualification_category: row.qualification_category
                        , area_proffessional_program: row.area_proffessional_program
                    }} dtype={dtype} mobile={mobile}/>
		        }
		    </div>

            <div className={css.card}>
                <Infoset value={row.infoset} dtype={dtype}/>
            </div>

		    <div className={css.card}>
		        <Signers value={row.signers}/>
		    </div>
		    <DocLowerMenu row={row}/>
		</>

	)
}

export default function DocPage({...props}){

    const location = useLocation();
    const previousLocation = location.state?.previousLocation;
    const hash = location.hash.replace('/', '');
	const url = useParams().url;
	const permit = useState()

    return (
		<Async value={
            get_doc(url, hash.slice(1)).then((doc) => {
                if(doc){
                    doc.url = url
                    doc.status = "Действительный"
                    if(doc.term_op_sign && doc.term_op_sign_stamp){
                        if(doc.replacement !== null)
                            doc.status = "Документ заменен"
                        else
                            doc.status = "Отменен"
                    }
                }
                return doc;
            })
		}
		 fallback={
            <DocLoader/>
		}
		>{row=>
		<>{
            row ?
            <PermitContext.Provider value={permit}>
                <Routes location={previousLocation || location}>
                    <Route path="/" element={<Doc row={row}/>}/>
                </Routes>
            </PermitContext.Provider>
            :
            <div style={{
                    margin: "5em auto 1em auto"
                    , textAlign: "center"
                    , fontSize: "40pt"
                    , fontWeight: "bold"
                    , color: "#ad3131"
                    }}
                >
                Документ не найден!
            </div>
        }</>
        }</Async>
    )
}