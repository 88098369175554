import * as yup from "yup";

export const emailValidation = yup.string()
    .email('Некорректный адрес')
    .required('Обязательно');

export const passwordValidation = yup.string()
    .min(10, 'Пароль должен быть не менее 10 символов')
    .matches(/[a-zа-я]+/, "Пароль должен содержать буквы в нижнем регистре")
    .matches(/[A-ZА-Я]+/, "Пароль должен содержать буквы в верхнем регистре")
    .matches(/[@$!%*#?&]+/, "Пароль должен содержать специальные символы")
    .matches(/\d+/, "Пароль должен содержать цифры")
    .required('Обязательно');

export const codeValidation = yup.string().required('Обязательно').matches(/^\d{4}$/, 'Код должен содержать 4 цифры');

const dateValidation = yup
    .mixed()
    .test('isValidDate', 'Введите корректную дату', function(value) {
        return value && !isNaN(value.getTime()) && value <= new Date();
    });

export const validationSchemas = {
    GET_EMAIL: yup.object().shape({
        email: emailValidation
    }),
    GET_PASSWORD: yup.object().shape({
        password: yup
            .string()
            .required('Обязательно')
    }),
    VERIFY_CODE: yup.object().shape({
        code: codeValidation
    }),
    REGISTER_DETAILS: yup.object().shape({
        email: emailValidation,
        lastName: yup.string().required('Обязательно'),
        firstName: yup.string().required('Обязательно'),
        middleName: yup.string(),
        birthDate: dateValidation.required('Обязательно').nullable(),
        phrase: yup.string().required('Обязательно'),
        phraseMsg: yup.string().required('Обязательно'),
        password: passwordValidation,
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать').required('Обязательно')
    }),
    VERIFY_REGISTER_CODE: yup.object().shape({
        code: codeValidation
    }),
    RESET_PASSWORD: yup.object().shape({
        phrase: yup
            .string()
            .required('Обязательно')
    })
};