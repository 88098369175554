import localDb , {storesModel, getKeyValues, isGenerated, isGeneratedKey} from './local-db';
import {parse_fields, isPlainObject} from './helpers';
import {alert} from './modals';

import {sha256}  from  'js-sha256';

export function isEqualRowKeys(key1, key2) {
	return key1.every((k,i)=>k===key2[i])
}

const kk = "?313142423424532525342525253452352389099809808090123?";

function enc(s) {
	s = s.replace(/[*]/g,'%2A')
	let sha = sha256(window.UserInfo.peer+kk+s);
	//console.log(window.UserInfo?.peer+kk+s)
	return sha;
}

function ts() {
	const c = (new Date()).valueOf()
	return ((c - window.UserInfo?.mt + window.UserInfo?.ts)/1000).toFixed(0)
}

/**
 * js-encoded key/values pairs
 * 
 * natural {key:value}, but this is unordered
 * [{key:value}] array is ordered(!) 
 * 
*/
function append_values(fd, values) {
	if(!Array.isArray(values)) values = [ values ] //always cast to array
	for(const e of values) { // ordered(!) array scan
		if(typeof e === 'string' ||
			e instanceof String) {
			fd.append(e, '')
			continue;
		}
		const e_own = {...e};
		for(const k in e_own) { 
						//objects(key/value pairs) is an element
			const v = e_own[k]
			if(Array.isArray(v)) {
				for(const a of v)
					if(a!==undefined)
						fd.append(k+'[]', a ?? '');
			} else if(isPlainObject(v)){ 
				for(const j in v)
					if(v[j]!==undefined)
						fd.append(k+'['+j+']', v[j] ?? '');
			} else {
					if(v!==undefined)
						fd.append(k, v ?? '');
			} 
		}
	}
	return fd;
}


/**
 * {f:true}
 * {f:false}
 * {f:null}
 * {f:value}
 * f
 * {not:...}
 * {or:...}
 * {"?param":...}
 * {"expression":[...params]}
 * [....] ===> and
 * 
 * ['a', {a:null}, {a:1}]
*/

function pack_filter(flt) {
	if(typeof flt === 'string') return flt;
	if(flt instanceof String) return flt;
	if(Array.isArray(flt)) {
		let ret = flt.map(pack_filter)
		return ret.length? ret : ret.length===1? ret[0] : undefined;
	}
	let count = 0;
	let ret = {};
	for(const i in flt) {
		let c = flt[i];
		let v = undefined;
		switch(i) {
			//TODO: values list for in/not in
			//case 'in':

			case 'or': case 'not':
				let r = pack_filter(c)
				if(r !== undefined ) {
					v = r;
				}
			break;
			default: //expression, named params
				if(Array.isArray(c) ?
					!c.find(x=>x===undefined)
					:
					c !== undefined
				) 
					v = c;
		}
		if(v!==undefined) {
			++count;
			ret[i] = v;
		}
	}
	return count?ret:undefined;
}

function pack_tilda_paths(values) {
	let ret = {}
	for(const i in values) {
		let a = i.split('~')
		let last = a.pop();
		let cur = ret;
		for(const c of a)
			cur = cur[c+'$'] = cur[c+'$']??{}
		cur[last] = values[i]
	}
	//console.log(ret)
	return ret;
}

function unpack_tilda(obj) {
	let arr = {};
	const unpack_tilda_rec = (prefix, obj) => {
		for(const i in obj)
			if(isPlainObject(obj[i]))
				unpack_tilda_rec(prefix.replace('$','~')+i, obj[i])
			else 
				arr[prefix.replace('$','~')+i] = obj[i] 
	} 
	for(const i in obj)
		if(isPlainObject(obj[i]))
			unpack_tilda_rec(i, obj[i])
		else 
			arr[i] = obj[i] 
	return arr;
}


function fields_to_object(fields, values) {
	fields = parse_fields(fields);
	let ret = {}
	if(!values) {
		for(const f of fields) 
			ret[f] = ''
		return pack_tilda_paths(ret);
	}
	if(Array.isArray(values)) {
		for(let i = 0; i < fields.length; ++i) {
			ret[ fields[i] ] =
						values[i] === null? '' : values[i] 
					;
		}
		return pack_tilda_paths(ret);
	}

	let arr = unpack_tilda(values);

	for(const f of fields) 
			ret[f] = arr[f] !== undefined?
						arr[f] : ''; 
	//console.log('-', arr, ret)
	return pack_tilda_paths(ret);
}

async function perform_db_op(DbX, store, params, fields, method) {
	let uri =
		"-f" in params?
		//rds
		localDb.storeRdsUri(store) ?? '/az2/server/rds'
		:
		//crud
		localDb.storeCrudUri(store) ?? '/az2/server/crud'
	let s_param = null;
	if(localDb.baseStore(store) !== store) {
		s_param = { "-s": store }
		store = localDb.baseStore(store) //switch to base store
	}
	if(fields) {
		params = [{...s_param, "-t": store}, params, ...fields]
		return DbX.fetch_get_as_json(uri, params)
	} else {
		let np = {}
		for(const i in params) {
			if(i[0]==='-') np[i] = params[i]
			else {
				if(i.indexOf('~')<0 && i.indexOf('$')<0)
					np[i] = params[i]
			}
		}
		let err = "-e" in params
		delete params["-e"]
		params = [{"-t": store}, np]

		let ret = err
		    ? await DbX.fetch_post_as_json_error(uri, params, method)
		    : await DbX.fetch_post_as_json(uri, params, method)

		if(ret.error) {
			await alert(ret.error.split('\n')[0])
			throw ret.error
		}
		return ret
	}
}

export function projectDbObj(store, fields, values) {
    let ret = {}
   	if(fields) {//if fields provided, restrict to them
	    for(const i of fields)
	    	if(i.indexOf('~')<0 &&
	    		(!store 
	    		|| 
	    			(!storesModel[store]?.[i]?.READONLY
	    			&&
	    			(storesModel[store]?.[i]?.type !== 'BLOB'
	    				||
	    				(values[i] !== true && values[i] !== false )
	    			))
	    		)
	    	){
		      	ret[i] = values[i]
		      	if(values[i+'$'])
		      		ret[i+'$'] = values[i+'$']
	    	}
	} else
		ret = values;
    return ret;
}

export async function in_local_cache(store, key) {
	let local = await localDb.get(store, key) 
	return !!local
}


const Db = {
// base methods (used this)
fetch_get(url, values) {
	url = new URL(url, window.location.href); //FIXME: page base url
	const search = append_values(
			new URLSearchParams(url.search)
			, values);
	url.search = search;

	return fetch(url, {
		method: 'GET'
		, credentials: "same-origin"
 		, headers: {
 			"X-Requested-With": "XMLHttpRequest"
 			, "X-TID": this.tid??''
 			, "X-SA": enc(search.toString())
 			, "X-TS": ts()
 		}
	})
}
,
fetch_post(url, values, method) {
	let search = append_values(new URLSearchParams(), values)
	return fetch(url, {
		method: method??'POST'
    	, body: search
 		, credentials: "same-origin"
 		, headers: {
 			"X-Requested-With": "XMLHttpRequest"
 			, "X-TID": this.tid??''
 			, "X-SA": enc(search.toString())
 			, "X-TS": ts()
 		}
	})
}
,
fetch_raw(url, body, method) {
	return fetch(url, {
		method: method??'POST'
    	, body
 		, credentials: "same-origin"
 		, headers: {
 			"X-Requested-With": "XMLHttpRequest"
 			, "X-TID": this.tid??''
 			, "X-SA": enc(body)
 			, "X-TS": ts()
 		}
	})
}
,
fetch_events(url, values) {
	url = new URL(url, window.location.href); //FIXME: page base url
	url.search = append_values(
			new URLSearchParams(url.search)
			, values);
	return new EventSource(url)	
}
,
async fetch_get_as_json(url, values) {
		return await this.fetch_get(url, values)
		.then( response => {
			if (!response.ok) {
				// alert("Ошибка базы данных")
			//response.text().then(result => console.log(result))
			response.text().then(result => alert(response.status + ' - ' + response.statusText + ': ' + result))
			}
			else{
				return response.json()
			}
		})
}
,
async fetch_post_as_json(url, values, method) {
		return await this.fetch_post(url, values, method)
		.then( response => {
		if (!response.ok) {
			// alert("Ошибка базы данных")
			//response.text().then(result => console.log(result))
			response.text().then(result => alert(response.status + ' - ' + response.statusText + ': ' + result))
		}
		else{
			return response.json()
		}
	})
}
,async fetch_get_as_json_error(url, values) {
    return await this.fetch_get(url, values)
    .then( response => {
        if (!response.ok) {
            return response.text().then(result => {
                return {
                    errorStatus: response.status
                    , errorStatusText: response.statusText
                    , result: result
                }
            })
        }
        else{
            return response.json()
        }
    })
}
,
async fetch_post_as_json_error(url, values, method) {
    return await this.fetch_post(url, values, method)
    .then( response => {
        if (!response.ok) {
            return response.text().then(result => {
                return {
                    errorStatus: response.status
                    , errorStatusText: response.statusText
                    , result: result
                }
            })
        }
        else{
            return response.json()
        }
    })
}
,
/*
	values::=

	{a: xxx} ==> a=xxx ===> $_REQUEST[a] = xxxx ===> sql_param_a = xxxx
	{a: [xxx]} ===> a[]=stringify(xxx) ===> 
				$_REQUEST[a]=[stringify(xxx)] ===> sql_param_a = [ xxx ]
	{a: {y:xxx}} ===> a[y]=stringify(xxx) ===> 
				$_REQUEST[a]=[y=>stringify(xxx)] ===> sql_param_a = {y: xxx }
*/
async call_function(fname, values) {
	const cv = {"-t": fname}
	for(const i in values) {
		const v = values[i]
		if(Array.isArray(v)) { // name = array ==> name[] = string ... name[] = string
			cv[i] = v.map(JSON.stringify)
		} else if(isPlainObject(v)) {
			// name = {object} ==> name[k1] = string .... name[kn] = string
			cv[i] = {}
			for(const k in v)
				cv[i][k] = JSON.stringify(v[k])
		} else
			cv[i] = v
	}
	return await this.fetch_post("/az2/server/call-func", cv)
	.then( response => {
		if (!response.ok) {
			// alert("Ошибка базы данных")
			//response.text().then(result => console.log(result))
			response.text().then(result => alert(response.status + ' - ' + response.statusText + ': ' + result))
		}
		else{
			return response.json()
		}
	})
}

,
/**
 * rds takes model predifined filter 
 * and provided filter and return json-array of data
*/

/** 
 * store - model table to get
 * fields - string 
 * 			or array of fields 
 * flt - see filters
 * */
async fetch_rds(store, fields, fixed, extra) {
	let flt = pack_filter(fixed);
	flt = JSON.stringify(flt||[]);

	let arr = await perform_db_op(this, store
		, { "-f":flt
			, "-p":extra?.vars
			, "-l": extra?.next_offset?
					  (+extra.limit||1)+1
					: extra?.limit
			, "-m": extra?.offset 
		}
		, parse_fields(fields))

	let ret = arr.map(values => fields_to_object(fields,values))

	if(extra?.next_offset){ 
		//  set next offset as a returend array property
		if(ret.length > (+extra.limit||1) ) {
			ret.next_offset = 
				extra.next_offset( ret.pop() )
		} else
			ret.next_offset = extra.offset
	}

	return ret;
}
,
/** 
 * store - model table to get
 * fields - string 
 * 			or array of fields 
 * key - value or array of values
 * def_value - return this if nothing fetched
 * 			if def_value === undefined -> return object with empty values
 * */
async fetch_row(store, fields, key, def_value) {
	key = Array.isArray(key) ? key : [ key ];
	let values = await perform_db_op(this, store, {"-k":key}, parse_fields(fields))
	return values || def_value===undefined ? 
			fields_to_object(fields, values) 
			: def_value;
}
,
async fetch_column(store, field, key) {
	key = Array.isArray(key) ? key : [ key ];
	let values = await perform_db_op(this, store, {"-k":key}, parse_fields(field))
	return values[0];
}
,
//get edited record + records from db
async fetch_list(store, fields, fixed, extra) {
	console.log('fetch '+store);

	let locals = await localDb.read_set(store, fixed) 
	//NOTE: needs to be patched (filtered out) later!!!
	//due to server filters record with differnt rules than client
	// i.e. it is used filter but client does not
	// so it is caller reposibility keep them in sync

	let result = new Map()
	for(const e of locals)
		result.set(JSON.stringify(getKeyValues(store,e)), 
			{data: e, modified: true}
		)

	let remote = await this.fetch_rds(store, fields, fixed, extra) // here fixed is a filter!!

	for(const e of remote) {
		const k = JSON.stringify(getKeyValues(store,e))
		if(!result.has(k))
			result.set(k, {data: e, modified: false})
	}

	return Array.from(result.values())
}
,
//try read local version
// if none read global one
// else select empty
async read_form(store, fields, key) {
	const local = await localDb.get(store, key)
	if(local) return {
				data:fields_to_object(fields, local)
				, local: !!isGeneratedKey(key)
				, modified:true
			};

	let values = await this.fetch_row(store, fields, key, null); //return if no row(!)
			
	return values ? { data: values, local: !!isGeneratedKey(key) }
			: { data: fields_to_object(fields), modified: null, local: !!isGeneratedKey(key) }
}
,
async read_column(store, field, key) {
	let local = await localDb.get(store, key)
	if(local) {
		local = unpack_tilda(local)
		return local[field]
	}
	return await this.fetch_column(store, field, key);
}
,
//generate new record (locally)
// should save with fixed values if given
async add_local(store, fields, fixed, values) {
	//if key has more then one field
	//take all key values from fixed and
	//generate one missing only

	const autokey = localDb.keyFieldsAuto(store)
	const keyFields = localDb.keyFields(store)

	const gen = autokey && !fixed[autokey] ?
				await localDb.generate(store)
				:
				fixed[autokey];

	let key = keyFields.map(k=>
			k === autokey? gen : fixed[k]
		)

	let ret = {...fields_to_object(fields), ...values, ...fixed}
	//save to ret
	keyFields.forEach((k,i)=> ret[ k ] = key[ i ])

	//put to storage
	await localDb.put(store, ret)

	return key;
}
,
async add_direct(store, fields, fixed, values) {
	values = {...values, ...fixed}

	const autokey = localDb.keyFieldsAuto(store)
	delete values[ autokey ];

	values = projectDbObj(store, fields, values)

	values = await perform_db_op(this, store, values, null, 'POST')

	return getKeyValues(store, values); //return key
}
,
// this is upsert command
async upsert_direct(store, fields, fixed, values) {
	values = {...values, ...fixed}

	const autokey = localDb.keyFieldsAuto(store)
	delete values[ autokey ];

	values = projectDbObj(store, fields, values)

	values = await perform_db_op(this, store, values, null, 'PUT' )

	return getKeyValues(store, values); //return key
}
,
async save_form(store, fields, values) {
	let storeKey = getKeyValues(store, values)
	values = projectDbObj(store, fields, values)

	const autokey = localDb.keyFieldsAuto(store)

	let ret = null
	if(autokey &&
		isGenerated( values[autokey] )
	){
		//new row, fake key
		let p = {...values} 
		delete p[ autokey ];//remove fake key
		values = await perform_db_op(this, store, p, null, 'POST')
		ret = getKeyValues(store, values); //return key from server(!)
	} else {
		let p = {"-k": storeKey, ...values} //ready to update!
		for(const i of localDb.keyFields(store))
			delete p[i]
		values = await perform_db_op(this, store, p, null, 'PATCH')
		ret = storeKey; //NOTE: key is unchanged. crud will return wrong(!) key: int converted to string
	}
	await localDb.remove(store, storeKey) //delete local version
	return ret; //return key
}
,
async save_direct(store, fields, values) {
	let storeKey = getKeyValues(store, values)
	values = projectDbObj(store, fields, values)

	const autokey = localDb.keyFieldsAuto(store)

	let ret = null
	if(autokey &&
		isGenerated( values[autokey] )
	) {
		//new row, fake key
		let p = {...values} // clear key!
		delete p[ autokey ];//remove fake key
		values = await perform_db_op(this, store, p, null, 'POST')
		ret = getKeyValues(store, values); //return key from server(!)
	} else {
		let p = {"-k": storeKey, ...values} //ready to update!
		for(const i of localDb.keyFields(store))
			delete p[i]
		values = await perform_db_op(this, store, p, null, 'PATCH')
		ret = storeKey; //NOTE: key is unchanged. crud will return wrong(!) key: int converted to string
	}
	return ret; //return key
}

, 
async remove_form(store, values) {
	let storeKey = getKeyValues(store, values)
	await localDb.remove(store, storeKey) //delete local version

	if(isGeneratedKey(storeKey)) return;

	await perform_db_op(this, store, {"-k": storeKey}, null, 'DELETE')
}
, 
async remove_direct(store, values) {
	let storeKey = getKeyValues(store, values)

	if(isGeneratedKey(storeKey)) return;

	await perform_db_op(this, store, {"-k": storeKey}, null, 'DELETE')
}
,
async remove_direct_error(store, values) {
	let storeKey = getKeyValues(store, values)

	if(isGeneratedKey(storeKey)) return;

	return await perform_db_op(this, store, {"-k": storeKey, "-e": true}, null, 'DELETE')
}

// helper
	, store(store, fixed, extra){ return (
		{ 	
		  Db: this
		  , store
		  , keyFields: localDb.keyFields(store)
		  , fixed
		  , extra
		  , fieldsCollector(fields) {
			  			let r = new Set(localDb.keyFields(store)); //keyFields
						if(fixed) for(const i in fixed) r.add(i)
						if(fields)
							for(const f of parse_fields(fields))
								r.add(f)
						return r;
		  		}
		  , withFields(fields) { return  ({...this, fields
			    , getKeyValues: values => getKeyValues(store,values)
				, fetch_list: () => this.Db.fetch_list(store, fields, fixed, extra) 
				, read: key => this.Db.read_form(store, fields, key)
				, read_direct: (key, fields_override) => this.Db.fetch_row(store, 
						fields_override?? fields, key)
				, append_local: values => this.Db.add_local(store, fields, fixed, values)
				, append_direct: values => this.Db.add_direct(store, fields, fixed, values)
				, save:  values => this.Db.save_form(store, fields, values)
				, remove: values => this.Db.remove_form(store, values) //no fields used!

				, stage: values => localDb.put(store, projectDbObj(null, fields, values))
				, reset: (key) => localDb.remove(store, key)
				, project: values => projectDbObj(store, fields,values)
				})
		}
	})}
}

export function shardedDbX(tid) {
	let withTid = Object.create(Db)
	withTid.tid = tid
	return withTid
}

export const mainDbX = Db //main (common) shard

