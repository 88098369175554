import R from "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'azlib/components/model.controls.reg'
import {initStores} from 'azlib/components/local-db'
import { decryptData } from 'azlib/components/crypt'
import {updateUa} from 'config/common'

//import {seat_code} from 'azlib/components/local_storage'
//import {createChannel} from 'azlib/components/icc'

import {
    getUinfoCookie
    , getPeerCookie
    , hasAZstate
} from 'azlib/components/cookies'

import data from 'app-cfg/model.data.ini'

// eslint-disable-next-line no-unused-expressions
R;
//console.log('!!!!!!!!', data);

function setLocationHash(hash) {
        window.location.hash = hash;
}

if(window.location.pathname === '/' && window.location.search) {
    console.log('need redirect')
    console.log(window.location)
    var hash = window.location.hash;
    window.history.replaceState(null,'',
        window.location.search.substr(1));
    setLocationHash(hash);
}

export async function uinfo_cached() {
  if(!hasAZstate()) return {}
  const u = getUinfoCookie()
  if(!u) return {}
  if(!window.localStorage['UserPwd']) return {}
  if(!window.localStorage['UserMagic']) return {}
  if(!window.localStorage['UserSalt']) return {}
  let pwd = window.localStorage['UserPwd']
  let c = window.localStorage['cachedUinfo']
  c = JSON.parse(c||'{}')
  if(c.personid !== +u
    || c.stamp < Date.now() - 1*60*1000
  ) {
    //reload
    let r = await fetch('/app/user/user-info', {headers: {"Cache-Control": "no-cache"}})
    const ts = (new Date(r.headers.get('date'))).valueOf()
    c = await r.json()
    c.stamp = Date.now()
    c.kv = JSON.parse(await decryptData(pwd, c.kv))
    c.pwd = window.localStorage['UserPwd']
    c.magic = window.localStorage['UserMagic']
    c.salt = window.localStorage['UserSalt']
    if(!c.kv) return {}

    window.localStorage['cachedUinfo'] = JSON.stringify(c)

    c.ts = ts
  } else {
    //cached
    let r = await fetch('/ping', {headers: {"Cache-Control": "no-cache"}})
    c.ts = (new Date(r.headers.get('date'))).valueOf()
  }

  c.peer = decodeURIComponent(getPeerCookie())
  c.mt = Date.now()

  return c
}

uinfo_cached()
.then( updateUa )
.then(()=>
  fetch('/app-bundle?'+(window.UserInfo?.app_bundle_stamp??''))
)
.then( r => r.json() )
.then( json => {
  json.data = data;
  window.AppBundle = json;
  initStores(window.AppBundle.model);
  console.log('bundle loaded')
})
.then(
  () => {
    ;
    //createChannel(`/icc/sub/${window.UserInfo.personid??0}/${seat_code()}`)
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
