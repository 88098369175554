import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import { titledName } from 'azlib/components/name_funcs'

import { useUserInfoContext } from 'UserInfo'

import { ReactComponent as Img_logout } from "app-img/logout.svg";
import { ReactComponent as Img_user } from "app-img/user.svg";
import { ReactComponent as Img_logo_small } from "app-img/logo1.svg";

import { OwnerLoginButton } from "registration/registrationForm";

const MainMenu = () => {
    const uinfo = useUserInfoContext();

    let ercdo_lnk='https://ercdo.obrnadzor.gov.ru'
    if ( window.location.hostname === '10.20.16.11'
            || window.location.hostname.startsWith('172.16.87.')
            || window.location.hostname === 'localhost'
        )
        ercdo_lnk='http://10.20.16.11'

    if (uinfo.userAreas.user)
        return (
            <div className="flexContainer pinRight">
                <p>{titledName(uinfo)}</p>
                <button>
                    <Link to="/lk">
                        <Img_user/>
                    </Link>
                </button>
                <button onClick={async ()=>{
                    await fetch('/logout')
                    window.open("/","_self")
                }}>
                    <Img_logout/>
                </button>
            </div>
        )

    return (
        <div className="flexContainer pinRight">
            <Link to={ercdo_lnk}><button className="Button-header">Личный кабинет организации</button></Link>
            <OwnerLoginButton>Личный кабинет обладателя ЦДО</OwnerLoginButton>
            <Link to="https://obrnadzor.gov.ru/gosudarstvennye-uslugi-i-funkczii/7701537808-gosfunction/formirovanie-i-vedenie-federalnogo-reestra-svedenij-o-dokumentah-ob-obrazovanii-i-ili-o-kvalifikaczii-dokumentah-ob-obuchenii/" target="_blank" rel="noreferrer"><button className="Button-header">ФИС ФРДО</button></Link>
        </div>
    )
}

export function Header(){
	let title = "Единый реестр"; //FIXME: need global hook for mainpage and lk

    return(
        <header id="App-header-anon">
          <Link to="/"><Img_logo_small alt="logo" title='Главная страница'/></Link>
          <span id="PageTitle-anon">{title} <br /> Цифровых документов об образовании</span>
          <MainMenu/>
        </header>
    )
}