import { useState } from 'react';

import { mainDbX } from 'azlib/components/db';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { checkLoginExists, responseText, senderMail, reLogin } from 'azlib/components/access'
import { PopupModal, useModalContext } from 'azlib/components/modals';
import { MaskedInput, DateInput } from 'azlib/components/std-controls'
import { encryptData, decryptData, jsonEscapeUTF, exportPbkdf2Key } from 'azlib/components/crypt'
import { passwordValidation } from "registration/components/regValidations";
import * as yup from 'yup';

import { useUserInfoContext } from 'UserInfo.js';

import { uinfo_changed, updateUa } from 'config/common'

import css from './account.module.css'

const validationSchema = yup.object().shape({
    last_name: yup
        .string()
        .required('Введите Вашу фамилию')
    , first_name: yup
        .string()
        .required('Введите Вашe имя')
    , snils: yup
        .string()
        .trim()
        .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}$/, 'Неверный формат')
    , bdate: yup
        .date()
        .nullable()
        .typeError('Некорректная дата')
        .required("Введите дату рождения")
        .test("","Некорректная дата", (val, props) => {
            if (val instanceof Date && !isNaN(val))
            {
                if ((new Date('1920-01-01') < val)  && (new Date()) > val) return true;
            }
            return false;
        })
});

function ChangeEmail({...props}){
    const uinfo = useUserInfoContext()
    const hide = useModalContext()

    const [disabled, setDisabled] = useState(true)
    const [button, setButton] = useState("")

    const handleSendCode = async (uinfo, login) => {
        await senderMail('code', login, uinfo.login)
        .then((res) => {
            if(res?.success){
                alert("Код подтверждения отправлен на указанную электронную почту!");
                setDisabled(false)
            }else{
                alert(res.error ?? 'Ошибка отправки кода подтверждения! Повторите попытку позднее или обратитесь в службу технической поддержки');
            }
        })

    };

    const handleReLogin = async (uinfo, new_login, code) => {
        await reLogin(uinfo, new_login, code)
        hide()
    }

    return (
        <Formik initialValues={{ email: '', code: '' }}
            validationSchema={
                yup.object({
                email: yup.string()
                    .email('Неверный адрес электронной почты')
                    .required('Обязательно')
                ,
                code: button === "code" ? yup.string()
                    .matches(/^\d{4}$/, 'Код должен содержать 4 цифры')
                    .required('Обязательно') : null
            })}
            onSubmit={async (v)=>{
                let login_hash = btoa(await exportPbkdf2Key(v.email, v.email))
                let exist = await checkLoginExists(login_hash)
                if(!exist?.error){
                    alert('Аккаунт с таким логином уже существует!');
                    return;
                }
                if(button === "email")
                    await handleSendCode(uinfo, v.email)
                if(button === "code")
                    await handleReLogin(uinfo, v.email, v.code)
            }}>
            <Form className="form-wrapper flexContainerColumn">
                <div className="form-title">ИЗМЕНЕНИЕ ПОЧТЫ</div>

                <p className="form-field-label">Новая почта</p>
                <div className="flexContainer">
                    <Field name="email" type="text"/>
                    <button type="submit" onClick={() => setButton("email")} >ОТПРАВИТЬ КОД</button>
                </div>
                <div className="error"><ErrorMessage name="email" /></div>

                <p className="form-field-label">Проверочный код</p>
                <div className="flexContainer">
                    <Field name="code" type="text" disabled={disabled}/>
                    <button type="submit" disabled={disabled} onClick={() => setButton("code")} >ПОДТВЕРДИТЬ</button>
                </div>
                <div className="error"><ErrorMessage name="code" /></div>
            </Form>
        </Formik>
    )
}


function ChangePwd({...props}){
    const uinfo = useUserInfoContext()
    const hide = useModalContext()

    return (
        <Formik initialValues={{ pwd_old: '', pwd: '', pwd_rep: ''}}
            validationSchema={
                yup.object({
                pwd_old: yup.string()
                    .required('Обязательно')
                    .test( 'test-pwd', 'Неверный пароль',
                        async function pwdValid(value){
                            if(value && await exportPbkdf2Key(value, atob(uinfo.salt)) === uinfo.pwd)
                                return true;
                            return false;
                        }
                    )
                , pwd: passwordValidation
                , pwd_rep: yup.string()
                    .required('Обязательно')
                    .oneOf([yup.ref('pwd'), null], 'Пароли должны совпадать')
            })}
            onSubmit={async (v)=>{
                await saveAccountPwd(uinfo, v)
                .then((res) => {
                    if(res?.error){
                        alert(res.error);
                    }else{
                        alert("Пароль успешно изменен!")
                        hide()
                    }
                })
            }}>
            <Form className="form-wrapper flexContainerColumn">
                <div className="form-title">ИЗМЕНЕНИЕ ПАРОЛЯ</div>

                <p className="form-field-label">Старый пароль</p>
                <Field name="pwd_old" type="password"/>
                <div className="error"><ErrorMessage name="pwd_old" /></div>

                <p className="form-field-label">Новый пароль</p>
                <Field name="pwd" type="password"/>
                <div className="error"><ErrorMessage name="pwd" /></div>

                <p className="form-field-label">Повторите пароль</p>
                <Field name="pwd_rep" type="password"/>
                <div className="error"><ErrorMessage name="pwd_rep" /></div>

                <button type="submit">ПОДТВЕРДИТЬ</button>
            </Form>
        </Formik>
    )

}


function ChangeSecretPhrase({...props}){
    const uinfo = useUserInfoContext()
    const hide = useModalContext()

    return (
        <Formik initialValues={{ phrase: '', phraseMsg: '', pwd: ''}}
            validationSchema={
                yup.object({
                phrase: yup.string()
                    .required('Обязательно')
                ,
                phraseMsg: yup.string()
                    .required('Обязательно')
                    .max(200, 'Подсказка не должна превышать 200 символов')
                ,
                pwd: yup.string()
                    .required('Обязательно')
                    .test( 'test-pwd', 'Неверный пароль',
                        async function pwdValid(value){
                            if(value && await exportPbkdf2Key(value, atob(uinfo.salt)) === uinfo.pwd)
                                return true;
                            return false;
                        }
                    )
            })}
            onSubmit={async (v)=>{
                await saveSecretPhrase(uinfo, v)
                .then((res) => {
                    if(res?.error){
                        alert(res.error);
                    }else{
                        alert("Секретное слово успешно изменено!")
                        hide()
                    }
                })
            }}>
            <Form className="form-wrapper flexContainerColumn">
                <div className="form-title">ИЗМЕНЕНИЕ СЕКРЕТНОГО СЛОВА</div>

                <p className="form-field-label">Новое секретное слово</p>
                <Field name="phrase" type="text"/>
                <div className="error"><ErrorMessage name="phrase" /></div>

                <p className="form-field-label">Новая подсказка к секретному слову</p>
                <Field name="phraseMsg" type="text"/>
                <div className="error"><ErrorMessage name="phraseMsg" /></div>

                <p className="form-field-label">Пароль</p>
                <Field name="pwd" type="password"/>
                <div className="error"><ErrorMessage name="pwd" /></div>

                <button type="submit">ПОДТВЕРДИТЬ</button>
            </Form>
        </Formik>
    )

}

export function AccountPage({user, ...props}){
	return(
	    <>
	        <AccountData user={user}/>
	    </>
	)
}

export function AccountData({user, ...props}) {
	let uinfo = useUserInfoContext();
	if(user) uinfo = user

	return (
	    <div className={css.accountBack+" card"}>
	    <h2>Личная информация</h2>
        <Formik
            initialValues={{
                last_name: uinfo.kv.last_name
                , first_name: uinfo.kv.first_name
                , middle_name: uinfo.kv.middle_name ?? ''
                , snils: uinfo.kv.snils ?? ''
                , bdate: uinfo.kv.bdate ?? ''
                }}
            validationSchema={validationSchema}
            onSubmit={async(values, event) => {
                event.setSubmitting(false);
                await saveAccountKV(uinfo, uinfo.login, Object.assign(uinfo.kv, values))
                .then((res) => {
                    if(res.error){
                        alert(res.error);
                    }else{
                        alert("Данные сохранены!")
                    }
                })
            }}>
            {({ isSubmitting }) => (
            <Form className="inputForm">
                <div className="flexContainer">
                    <div className="flexColumn" style={{marginRight: '2em'}}>
                        <label>Фамилия</label>
                        <Field
                            name="last_name"
                            type="text"
                            placeholder="Фамилия"/>
                        <ErrorMessage name="last_name" component="div" className="error" />
                        <label>Имя</label>
                        <Field
                            name="first_name"
                            type="text"
                            placeholder="Имя"/>
                        <ErrorMessage name="first_name" component="div" className="error" />
                        <label>Отчество</label>
                        <Field
                            name="middle_name"
                            type="text"
                            placeholder="Отчество"/>
                        <ErrorMessage name="middle_name" component="div" className="error" />
                    </div>
                    <div className="flexColumn" style={{marginRight: '2em'}}>
                        <label>СНИЛС</label>
                        <Field
                            name="snils"
                            type="text"
                            placeholder="СНИЛС"
                            mask="000-000-000 00"
                            as={MaskedInput}/>
                        <ErrorMessage name="snils" component="div" className="error" />
                        <label>Дата рождения</label>
                        <Field name="bdate" as={DateInput} autoComplete="off"/>
                        <ErrorMessage name="bdate" component="div" className="error" />
                    </div>
                    <div className="flexColumn">
                        <label>Email</label>
                        <div className="flexContainer notWrap">
                            <input disabled={true} type="text" value={uinfo.kv.contact_email}/>
                            <PopupModal
                                trigger={(handlers)=><span type="button" {...handlers}>Изменить</span>}>
                                <ChangeEmail/>
                            </PopupModal>
                        </div>
                        <label>Пароль</label>
                        <div className="flexContainer notWrap">
                            <input disabled={true} type="password" value="********"/>
                            <PopupModal
                                trigger={(handlers)=><span type="button" {...handlers}>Изменить</span>}>
                                <ChangePwd/>
                            </PopupModal>
                        </div>
                        <label>Секретное слово</label>
                        <div className="flexContainer notWrap">
                            <input disabled={true} type="password" value="********"/>
                            <PopupModal
                                trigger={(handlers)=><span type="button" {...handlers}>Изменить</span>}>
                                <ChangeSecretPhrase/>
                            </PopupModal>
                        </div>
                    </div>
                </div>
                <br/>
                <button type="submit">Изменить</button>
            </Form>
            )}
        </Formik>
        </div>
	)
}

export async function saveAccountPwd(uinfo, values){

    let kv_new = Object.assign(uinfo.kv, values);
    let pwd = kv_new.pwd

    delete kv_new.pwd;
    delete kv_new.pwd_rep;

    let pwd_hash = await exportPbkdf2Key(pwd, atob(uinfo.salt))
    let kv = await encryptData(pwd_hash, jsonEscapeUTF(JSON.stringify(kv_new)))
    let magic_hash = btoa(await exportPbkdf2Key(await exportPbkdf2Key(atob(uinfo.magic), pwd_hash), atob(uinfo.login)))

    let phrase_old = await decryptData(uinfo.pwd, await mainDbX.fetch_column('owners', 'phrase_hash', [uinfo.ownerid]))
    let phrase_hash_old = await exportPbkdf2Key(phrase_old, atob(uinfo.salt))

    let pwd_data = JSON.stringify({
        phrase: phrase_old
        , password: btoa(pwd_hash)
    })

    return await responseText(await mainDbX.fetch_post("/repwd/"+uinfo.login
        , {
            login: uinfo.login
            , kv: kv
            , magic_hash: magic_hash
            , phrase_crypted: await encryptData(pwd_hash, phrase_old)
            , pwd_crypted: await encryptData(phrase_hash_old, jsonEscapeUTF(pwd_data))
        })
    )
    .then(async (res) => {
        if(!res.error){
            window.localStorage['UserPwd'] = uinfo.pwd = pwd_hash
            updateUa(uinfo)
            return await uinfo_changed()
        }
        return res
    })
}

export async function saveSecretPhrase(uinfo, values){

    let phrase_hash = await exportPbkdf2Key(values.phrase, atob(uinfo.salt))
    let pwd_info = JSON.stringify({
        phrase: values.phrase
        , password: btoa(uinfo.pwd)
    });

    let pwd_crypted = await encryptData(phrase_hash, jsonEscapeUTF(pwd_info))
    let phrase_crypted = await encryptData(uinfo.pwd, values.phrase)

    return await responseText(await mainDbX.fetch_post("/rephrase/"+uinfo.login
            , {
                phrase_crypted: phrase_crypted
                , phrase_msg: values.phraseMsg
                , pwd_crypted: pwd_crypted
            })
        )
        .then(async (res) => {
            if(!res.error){
                return await uinfo_changed()
            }
            return res
        })
}

export async function saveAccountKV(uinfo, login_hash, kv){

    let crypted_kv = await encryptData(uinfo.pwd, jsonEscapeUTF(JSON.stringify(kv)))
    let magic_hash = btoa(await exportPbkdf2Key(await exportPbkdf2Key(atob(uinfo.magic), uinfo.pwd), atob(login_hash)))

    return await mainDbX.save_direct('owners', null,{
        ownerid : uinfo.ownerid
        , login: login_hash
        , kv : crypted_kv
        , magic_hash : magic_hash
    })
    .then(async (res) => {
        if(!res.error){
            uinfo.kv = kv
            updateUa(uinfo)
            return await uinfo_changed()
        }
        return res
    })
}
