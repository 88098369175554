import {later} from 'azlib/components/helpers'
import {showModalProcessing} from 'azlib/components/modals'

export function updateUa(uinfo){
	window.UserInfo = uinfo || {};

	let ua = {}
    ua.user = !!window.UserInfo.login;

	window.UserInfo.userAreas = ua;

	console.log('ua ok '+ window.UserInfo.app_bundle_stamp)
}

/**
 * wait until uinfo updated
 * now it called at:
 * persons
 * if user orgs changed, uinfo of course changed too!
 * groups add/del: same as orginfo now
 */
export async function uinfo_changed() {
	await showModalProcessing(
		async (hide)=>{
			for(let i = 0; i < 10; ++i) {
				if(!window.localStorage['cachedUinfo']) return true;
				console.log('wait...')
				await later(1000)
			}
			return null;
		}
		, props=>
		<div>Сохранение</div>
	)
}
