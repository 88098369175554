import { useContext } from 'react'
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { exportPbkdf2Key, digestSHA256 } from 'azlib/components/crypt'
import { useModalContext } from 'azlib/components/modals'

import { saveAccountKV } from 'accounts/account'
import { docFindExtParam, usePermitContext } from 'docs/doc'
import { useUserInfoContext } from 'UserInfo'

import { object, string } from 'yup';

export default function DocAdd({hash, doc, ...props}) {
    const uinfo = useUserInfoContext()
    const [, setPermit] = usePermitContext()
    const doc_mk = docFindExtParam(doc, 'master_key')
    const hide = useModalContext()

	return <Formik initialValues={{master_key: ''}}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={
            object({
                master_key: string()
                    .required('Необходим код подтверждения')
                    .test( 'test-mk', 'Неверный код подтверждения',
                        async function masterKeyValid(value){
                            try{
                                if(await exportPbkdf2Key(value, await digestSHA256(value)) === atob(doc_mk))
                                    return true;
                                return false;
                            }catch(error){
                                return false;
                            }
                        }
                    )
            })}
        onSubmit={async ()=>{
                    let new_cdo = {
                        'url': doc.url
                        , 'hash' : hash
                        , 'doctype_name': doc.doctype_name
                        , 'doctype_kind': doc.doctype_kind
                        , 'year_completion': doc.year_completion
                        , 'our_number': doc.our_number
                        , 'gov': doc.infoset_orginfo.name
                    }

                    let ed_level = doc.doctype_kind.split('-')[0] === 'mp'? 'mp' : doc.doctype_kind.split('-')[0].charAt(0);

                    if(!(ed_level in uinfo.kv.cdo_list))
                        uinfo.kv.cdo_list[ed_level] = []
                    uinfo.kv.cdo_list[ed_level].push(new_cdo)

                    await saveAccountKV(uinfo, uinfo.login, uinfo.kv)
                    //window.document.location.reload()
                    setPermit('-o')
                    hide()
                }
            }>
        <Form className="form-wrapper fixed flexContainerColumn" >
            <div className="form-title">ДЛЯ ДОБАВЛЕНИЯ ДОКУМЕНТА В ЛК ВВЕДИТЕ КОД ПОДТВЕРЖДЕНИЯ, ПЕРЕДАННЫЙ ВАМ ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИЕЙ</div>
            <p className="form-field-label">Код подтверждения</p>
            <Field name="master_key" type="text"/>
		    <div className="error"><ErrorMessage name="master_key" /></div>
            <button type="submit">ДОБАВИТЬ ДОКУМЕНТ</button>
        </Form>
    </Formik>
}