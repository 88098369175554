import React from 'react';
import { Routes, Route } from "react-router-dom"

import {AccountPage} from 'accounts/account'
import {DocsList} from 'docs/docslist'

import {FaqPage} from 'config/faq/faq-page'

const MainApp = () => {

	return (
  	  <Routes>
		<Route path="faq" element={<FaqPage/>} />
        <Route index element={<>
            <AccountPage />
            <DocsList />
        </>} />
        <Route path="*" element="404" />
	  </Routes>
    );
}
export default MainApp;
