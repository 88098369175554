import './App.css';
import {
  BrowserRouter as Router, Routes,Route
} from "react-router-dom"
import React, {useState, useEffect} from 'react';

import { UserInfoContext } from './UserInfo.js';
import { DeviceContext } from './UserDevice.js';

import AnonApp from './AnonApp.js';
import RestorePage from 'accounts/restorer'

import {ConnectionMonitor, MaintenanceIndicator} from 'azlib/components/connectionMonitor'

function App() {
  const [windowSize, setWindowSize] = useState(
    window.innerWidth < 500 ?'mobile':''
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth < 500 ?'mobile':'');
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Router>
    <Routes>
      <Route path="/repass/:url" element={<RestorePage />}/>
      <Route path="*" element={
        <div id="App">
          {/*<DevOpsUI/>*/}
          <DeviceContext.Provider value={windowSize}>
              <ConnectionMonitor
                disconnected={
                  <div id="appDisconnectedMarker" />
                }
              >
              <MaintenanceIndicator
                on={<div id="appMaintenanceIndicator" />}
              >
              <UserInfoContext.Provider value={window.UserInfo}>
                      <AnonApp/>
              </UserInfoContext.Provider>
              </MaintenanceIndicator>
              </ConnectionMonitor>
          </DeviceContext.Provider>
        </div>
      } />
    </Routes>
    </Router>
  );
}

export default App;
