import { useState } from 'react'
import { Field, ErrorMessage } from 'azlib/components/formik-fields'
import { Formik, Form } from 'formik';

import { mainDbX } from 'azlib/components/db';
import { localISOtoday } from 'azlib/components/calendar';
import { copyToBuffer } from 'azlib/components/helpers'
import { alert, confirm, useModalContext } from 'azlib/components/modals';
import { saveAccountKV } from 'accounts/account'
import { encryptData, jsonEscapeUTF, generateRandText } from 'azlib/components/crypt'

import { object, string } from 'yup';
import { useUserInfoContext } from 'UserInfo.js';

import { ReactComponent as ImgStepBack } from "app-img/stepBack.svg";


export function DocShare({doc, ...props}) {
    const uinfo = useUserInfoContext()
    const hide = useModalContext()
    const [urls, setUrls] = useState([...uinfo.kv.docs_shared[doc.url] ?? []])

    console.log("START", urls)

    return (
        <Formik initialValues={{description:''}}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={
                object().shape({
                    description: string()
                        .required('Необходимо наименование ссылки')
                })}
            onSubmit={async (v) => {
                setUrls(await genDocUrl(v.description, doc, uinfo))
            }}>
            <Form className="form-wrapper flexContainerColumn" >
                <ImgStepBack style={{marginLeft:"-45px"}} className="back-button" onClick={()=>hide()}/>
                <div className="form-title">НАСТРОЙКИ ДОСТУПА</div>
                <div>
                    <p className="form-field-label">Наименование</p>
                    <div className="flexContainer">
                        <Field name="description" type="text"/>
                        <button className="pinRight" type="submit">СГЕНЕРИРОВАТЬ ССЫЛКУ</button>
                    </div>
                    <ErrorMessage name="description" component="div" className="error" />
                </div>
                <div className="card">
                {
                urls.length > 0 ?
                    urls.map((ds, i)=>
                        <div key={i} className="flexContainerColumn">
                            <p className="form-field-label">Наименование</p>
                            <div className="flexContainer">
                                <Field
                                    name="description"
                                    type="text"
                                    readOnly={true}
                                    value = {ds.description}/>
                                <div className="flexContainer">
                                    <button type="button" onClick={async ()=>{
                                            await copyToBuffer(
                                            //urlRefs.current[i].current.value
                                            'https://'+window.location.hostname
                                            +(window.location.port !== 80 ? ':'+window.location.port : '')
                                            +'/doc/'+ds.url+'#'+ds.hash
                                            )
                                        }}
                                        >СКОПИРОВАТЬ ССЫЛКУ</button>
                                    <button type="button" onClick={async (e)=>{
                                            await confirm('Удалить?', true)
                                            .then(async () => {
                                                setUrls(await dropDocUrl(doc.url, ds, uinfo))
                                            })
                                        }}
                                        >УДАЛИТЬ ССЫЛКУ</button>
                                </div>
                            </div>
                        </div>
                    )
                :
                <p>У Вас пока нет ссылок доступа к данному ЦДО</p>
                }
                </div>
            </Form>
        </Formik>
    )
}

async function genDocUrl(shared_name, real_doc, uinfo){

    let shared = {...real_doc}
    let real_doc_key = shared.url

    delete shared.url
    shared.ext_info = shared.ext_info.filter((e) => e.parameter !== 'master_key')

    if(!uinfo.kv.docs_shared){
        uinfo.kv.docs_shared = {}
    }

    let hash = generateRandText(16);
    let new_url = generateRandText(32);

    let new_share = {
        url: new_url
        , hash: hash
        , description: shared_name
        , stamp: localISOtoday()
    }
    console.log(uinfo.kv.docs_shared[real_doc_key])

    if(!uinfo.kv.docs_shared[real_doc_key])
        uinfo.kv.docs_shared[real_doc_key] = [new_share]
    else
        uinfo.kv.docs_shared[real_doc_key] = [...uinfo.kv.docs_shared[real_doc_key]
            , new_share
        ]
    console.log(uinfo.kv.docs_shared[real_doc_key])

    mainDbX.add_direct('archive_shared'
            , null
            , {}
            , {
                doc_key: new_url
                , data: await encryptData(hash, jsonEscapeUTF(JSON.stringify(shared)))
                , stamp: localISOtoday()
            }
        )
        .then(async() => {
                await saveAccountKV(uinfo, uinfo.login, uinfo.kv)
            }
        )
        .catch((error) => {
                alert("Не удалось создать ссылку! Попробуйте еще раз позднее или обратитесь в службу технической поддержки")
            }
        )
    return uinfo.kv.docs_shared[real_doc_key]
}

async function dropDocUrl(real_doc_key, shared, uinfo){
    await mainDbX.remove_direct_error('archive_shared', {doc_key: shared.url})
        .then(async(e) => {
            if(e?.errorStatus && e?.errorStatus !== 404){
                throw e
            }
            uinfo.kv.docs_shared[real_doc_key] = uinfo.kv.docs_shared[real_doc_key].filter((e)=> e !== shared)
            await saveAccountKV(uinfo, uinfo.login, uinfo.kv)
        })
        .catch((error) => {
            alert("Не удалось удалить ссылку! Попробуйте еще раз позднее или обратитесь в службу технической поддержки")
            console.log(error)
        })
    return uinfo.kv.docs_shared[real_doc_key]
}